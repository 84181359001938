import { atom } from "jotai";
import cloneDeep from "lodash/cloneDeep";

import { Coordinate } from "../../subjectProperty/domain/Coordinate";
import { CustomClusterDefinition } from "../domain/CustomClusterDefinition";
import { CustomClusterFilter } from "../domain/CustomClusterFilter";
import {
  selectCustomClusterIdAtom,
  unselectCustomClusterIdAtom,
} from "./selectedCustomClustersState";

const _customClustersAtom = atom<Record<number, CustomClusterDefinition>>({});

export const customClustersAtom = atom((get) =>
  Object.entries(get(_customClustersAtom)).map(
    ([clusterId, cluster]): [number, CustomClusterDefinition] => [
      parseInt(clusterId),
      cluster,
    ],
  ),
);

export const customClusterIdsAtom = atom((get) => {
  const customClusters = get(_customClustersAtom);
  const customClusterIds = Object.keys(customClusters).map((x) => parseInt(x));

  return customClusterIds;
});

export const createClusterAtom = atom(
  null,
  (get, set, cluster: CustomClusterDefinition) => {
    const customClusters = get(_customClustersAtom);

    const customClusterIds = get(customClusterIdsAtom);
    const largestId = Math.max(...customClusterIds);

    const isLargestIdValid = customClusterIds.length > 0 && !isNaN(largestId);

    const nextId = isLargestIdValid ? largestId + 1 : 1;

    // [TF-411] Update all other custom clusters' filters when adding a new one
    const updatedCustomClusters = updateAllCustomClustersWithFilter(
      cluster.filters,
      customClusters,
    );

    set(_customClustersAtom, {
      ...updatedCustomClusters,
      [nextId]: cluster,
    });

    set(selectCustomClusterIdAtom, nextId);

    return nextId;
  },
);

export const updateClusterAtom = atom(
  null,
  (get, set, clusterId: number, cluster: CustomClusterDefinition) => {
    const customClusters = get(_customClustersAtom);

    // [TF-411] Update all other custom clusters' filters when editing one
    const updatedCustomClusters = updateAllCustomClustersWithFilter(
      cluster.filters,
      customClusters,
    );

    set(_customClustersAtom, {
      ...updatedCustomClusters,
      [clusterId]: cluster,
    });
  },
);

export const updateCoreClusterPointsAtom = atom(
  null,
  (get, set, points: Coordinate[]) => {
    const customClusters = get(_customClustersAtom);

    const customClusterIds = get(customClusterIdsAtom);
    const smallestId = Math.min(...customClusterIds);

    const newCustomCluster = {
      ...customClusters[smallestId],
      points: points,
    };

    set(_customClustersAtom, {
      ...customClusters,
      [smallestId]: newCustomCluster,
    });
  },
);

export const deleteClusterAtom = atom(null, (get, set, clusterId: number) => {
  const customClusters = cloneDeep(get(_customClustersAtom));

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete customClusters[clusterId];

  set(unselectCustomClusterIdAtom, clusterId);
  set(_customClustersAtom, customClusters);
});

export const deleteAllClustersAtom = atom(null, (get, set) => {
  set(_customClustersAtom, {});
});

function updateAllCustomClustersWithFilter(
  filters: CustomClusterFilter[],
  existingCustomClusters: Record<number, CustomClusterDefinition>,
): Record<number, CustomClusterDefinition> {
  const customClusterIds = Object.keys(existingCustomClusters).map((x) =>
    parseInt(x),
  );

  const updatedCustomClusters = Object.fromEntries(
    customClusterIds.map((customClusterId) => {
      const updatedCustomCluster = cloneDeep(
        existingCustomClusters[customClusterId],
      );
      updatedCustomCluster.filters = cloneDeep(filters);

      return [customClusterId, updatedCustomCluster];
    }),
  );

  return updatedCustomClusters;
}
