import { atom } from "jotai";
// import { parsePropertyList } from "../utils/PropertyListParser";

export interface CompWeight {
  listing_number: string;
  weight_value: number;
}

export interface CompAdjustment {
  listing_number: string;
  adjustment_value: number;
}

export const _compWeightsAtom = atom<CompWeight[]>([]);

export const _compCountAtom = atom(0);

export const readCompWeightsAtom = atom((get) => get(_compWeightsAtom));

export const readCompWeightAtom = atom((get) => (listing_number: string) => {
  const compWeights = get(_compWeightsAtom);
  return compWeights.find((comp) => comp.listing_number === listing_number);
});

export const writeCompWeightsAtom = atom(
  null,
  (get, set, newCompWeight: CompWeight) => {
    const oldCompWeights = get(_compWeightsAtom);
    const otherCompWeights = oldCompWeights.filter(
      (x) => x.listing_number !== newCompWeight.listing_number,
    );

    set(_compWeightsAtom, [...otherCompWeights, newCompWeight]);
  },
);

export const clearCompWeightsAtom = atom(null, (_get, set) => {
  set(_compWeightsAtom, []);
});

export const compWeightsCountAtom = atom((get) => get(_compWeightsAtom).length);

export const compCountAtom = atom((get) => get(_compCountAtom));

export const compWeightsDefinedAtom = atom((get) =>
  get(_compWeightsAtom).filter((x) => x.weight_value == 0),
);

// key is the comp listing_number and feature name, ex: "1234567_gla"
export const _compFeatureAdjustmentsAtom = atom<CompAdjustment[]>([]);

export const readCompFeatureAdjustmentsAtom = atom((get) =>
  get(_compFeatureAdjustmentsAtom),
);

export const writeCompFeatureAdjustmentsAtom = atom(
  null,
  (get, set, newCompAdjustment: CompAdjustment) => {
    // console.log("newCompAdjustment", newCompAdjustment);
    const oldCompAdjustments = get(_compFeatureAdjustmentsAtom);
    const otherCompAdjustments = oldCompAdjustments.filter(
      (x) => x.listing_number !== newCompAdjustment.listing_number,
    );

    set(_compFeatureAdjustmentsAtom, [
      ...otherCompAdjustments,
      newCompAdjustment,
    ]);
  },
);

export const clearCompFeatureAdjustmentsAtom = atom(null, (_get, set) => {
  set(_compFeatureAdjustmentsAtom, []);
});
