import { useAtomValue } from "jotai";

import {
  selectedCustomClusterIdsAtom,
  selectedCustomClustersAtom,
} from "../state/selectedCustomClustersState";

export function useSelectedCustomClusters() {
  const selectedCustomClusters = useAtomValue(selectedCustomClustersAtom);

  return selectedCustomClusters;
}

export function useSelectedCustomClusterIds() {
  const selectedCustomClusterIds = useAtomValue(selectedCustomClusterIdsAtom);

  return selectedCustomClusterIds;
}
