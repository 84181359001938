import { atom } from "jotai";

import { customClustersAtom } from "./customClustersState";

const _selectedCustomClusterIdsAtom = atom<number[]>([]);

export const selectedCustomClusterIdsAtom = atom((get) =>
  get(_selectedCustomClusterIdsAtom),
);

export const selectCustomClusterIdAtom = atom(
  null,
  (get, set, customClusterId: number) => {
    const selectCustomClusterIds = get(selectedCustomClusterIdsAtom);

    if (!selectCustomClusterIds.includes(customClusterId)) {
      set(_selectedCustomClusterIdsAtom, [
        ...selectCustomClusterIds,
        customClusterId,
      ]);
    }
  },
);

export const unselectCustomClusterIdAtom = atom(
  null,
  (get, set, customClusterId: number) => {
    const selectCustomClusterIds = get(selectedCustomClusterIdsAtom);

    set(
      _selectedCustomClusterIdsAtom,
      selectCustomClusterIds.filter((x) => x != customClusterId),
    );
  },
);

export const toggleCustomClusterIdAtom = atom(
  null,
  (get, set, customClusterId: number) => {
    const selectCustomClusterIds = get(selectedCustomClusterIdsAtom);

    if (!selectCustomClusterIds.includes(customClusterId)) {
      set(selectCustomClusterIdAtom, customClusterId);
    } else {
      set(unselectCustomClusterIdAtom, customClusterId);
    }
  },
);

export const clearCustomClusterIdsAtom = atom(null, (get, set) => {
  set(_selectedCustomClusterIdsAtom, []);
});

// Uses customClustersAtom from customClustersState
export const selectedCustomClustersAtom = atom((get) => {
  const customClusters = get(customClustersAtom);
  const selectedCustomClusterIds = get(selectedCustomClusterIdsAtom);

  return customClusters.filter((x) => selectedCustomClusterIds.includes(x[0]));
});
